<template>
  <div class="content">
    <v-app>
      <div>
        <v-row justify="center">
          <v-dialog v-model="errorModal" persistent max-width="290">
            <v-card>
              <v-card-title style="word-break:normal">
                Something went wrong...
              </v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="green darken-1" text @click="closeDialogErrorModal">
                  OK
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>
      </div>
      <div class="adminContainer">
        <div class="leftDiv">
          <img class="divImage" src="https://d3vsnl8idgwrlw.cloudfront.net/adminImage.png"/>
        </div>
        <div class="loginContainer">
          <div class="loginBox" v-if="showLoginComponent">
            <h1>Sign in </h1>
            <div class="formDiv">
              <div>
                <label for="emailId" style="display: block; text-align: left;">Email<span style="color: red;">*</span></label>
                <v-text-field label="Enter Your Email" 
                  solo 
                  flat = false
                  type="email"
                  background-color="rgba(244, 246, 250, 1)"
                  v-model="email"
                  :rules="emailInputRules"
                ></v-text-field>
              </div>
              <div>
                <label for="passwordId" style="display: block; text-align: left;">Password<span style="color: red;">*</span></label>
                <v-text-field label="Enter Your Password" 
                  solo
                  flat = false
                  :rules="passwordInputRules"
                  :type="show1 ? 'text' : 'password'" 
                  background-color="rgba(244, 246, 250, 1)"
                  v-model="password"
                  :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append="show1 = !show1"
                ></v-text-field>
              </div>
              <div class="rememberMeDiv">
                <v-checkbox label="Remember me"></v-checkbox>
                <!-- <span>Forgot Password?</span> -->
              </div>
              <v-btn
                color="primary"
                :loading="submitLogin"
                variant="elevated"
                block
                class="mt-2"
                @click="signInViaEmailPassword"
                :disabled="isSubmitBtnDisableForEmailPassword"
              >Submit</v-btn>
            </div>
            <div style="margin: 30px 0px;">OR</div>
            <div v-if="!isLoading">
              <div ref="googleLoginBtn" class="googleBtn"/>
            </div>
            <div v-else>
              <v-progress-circular
                indeterminate
                color="primary"
              ></v-progress-circular>
              <p>Please wait. Verifying details.</p>
            </div>
          </div>
          <div v-else class="loginBox">
          <div class="spc">
            <h1>Set New Password </h1>
            <div class="formDiv">
              <div>
                <label for="newPasswordId" style="display: block; text-align: left;">Password<span style="color: red;">*</span></label>
                <v-text-field label="Enter Your Password" 
                  solo
                  flat = false
                  :rules="newPasswordInputRules"
                  :type="showNewPassword ? 'text' : 'password'" 
                  background-color="rgba(244, 246, 250, 1)"
                  v-model="newPassword"
                  :append-icon="showNewPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append="showNewPassword = !showNewPassword"
                ></v-text-field>
              </div>
              <div>
                <label for="newPasswordConfirmId" style="display: block; text-align: left;">Confirm Password<span style="color: red;">*</span></label>
                <v-text-field label="Re-enter the password" 
                  solo
                  flat = false
                  :rules="newPasswordInputRules"
                  :type="showConfirmPassword ? 'text' : 'password'" 
                  background-color="rgba(244, 246, 250, 1)"
                  v-model="newPasswordConfirm"
                  :append-icon="showConfirmPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append="showConfirmPassword = !showConfirmPassword"
                ></v-text-field>
              </div>
              <br/>
              <v-btn
                color="primary"
                :loading="submitLogin"
                variant="elevated"
                block
                class="mt-2"
                @click="updatePassword"
                :disabled="setNewPasswordSubmitIsDisabled || submitLogin"
              >Submit</v-btn>
            </div>
          </div>
          </div>
        </div>
      </div>
    </v-app>
  </div>
</template>

<script>
import axios from "axios";
import jwt_decode from "jwt-decode";
import { base_axios_instance } from '../../../utils/axios_utils';

export default {
  name: "LoginPage",

  data() {
    return {
      mode: '',
      gauthToken: '',
      showLoginComponent : true,
      currentUser : "",
      errorModal : false,
      isLoading: false,
      isDisabled: true,
      disableTimer: null,
      newPassword: '',
      newPasswordConfirm: '',
      email: '',
      password: '',
      submitLogin: false,
      show1: false,
      showNewPassword: false,
      showConfirmPassword: false,
      allErrors: ['Invalid e-mail.','Min 8 characters', 'Required.' ],
      emailInputRules: [(value) => !!value || 'Required.', (value) => {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            const allowedEmail = ["kbzbank.com", "kbxdigital.com", "kbxholdings.com","doodleblue.com","mymedicine.com.mm"].indexOf(value.split("@")[1]);
            return pattern.test(value) && allowedEmail >= 0  || 'Invalid e-mail.'
          }],
      passwordInputRules: [(value) => !!value || 'Required.'],
      newPasswordInputRules: [(value) => !!value || 'Required.', (v) => v.length >= 8 || 'Min 8 characters',],
    };
  },

  mounted(){    
    this.currentUser = this.$cookies.get("token");
    if(this.currentUser){
      this.isLoading = true;
       var getAccessData = {
        token: this.currentUser,
        typeOfUser: "ADMIN",
        adminType: "ADMIN",
      };
      axios
        .post(process.env.VUE_APP_BACKEND_URL + "/getAdmin", getAccessData)
        .then((getAdminResponse) => {
              this.$router.push({
                 name:"AdminHome"
              });
          })
        .catch((getAdminError)=>{
          this.$cookies.remove("token");
          this.errorModal = true;
          this.isLoading = false;
          this.renderSignInButton();
          })
    } else {
        this.renderSignInButton();
    }
  },
  computed: {
    isSubmitBtnDisableForEmailPassword: function() {
      return (this.emailInputRules.some((rule) => this.allErrors.includes(rule(this.email))) || this.passwordInputRules.some((rule) => this.allErrors.includes(rule(this.password))));
    },
    setNewPasswordSubmitIsDisabled: function() {
      return ((this.newPassword !== this.newPasswordConfirm) || this.newPasswordInputRules.some((rule) => this.allErrors.includes(rule(this.newPassword))) || this.newPasswordInputRules.some((rule) => this.allErrors.includes(rule(this.newPasswordConfirm))));
    }
  },
  methods: {
    async updatePassword() {
      this.submitLogin = true;
      let newEncryptedPassword = this.CryptoJS.AES.encrypt(this.newPassword, process.env.VUE_APP_ENCRYPTION_KEY).toString()
      try {
        let reqBody = {};
        if (this.mode === 'GAUTH') {
          reqBody = {
            password: newEncryptedPassword,
            token: this.gauthToken,
            mode: 'GAUTH',
            adminType: 'ADMIN'
          }
        } else {
          let oldEncryptedPassword = this.CryptoJS.AES.encrypt(this.password, process.env.VUE_APP_ENCRYPTION_KEY).toString()
          reqBody = {
            email: this.email,
            oldPassword: oldEncryptedPassword,
            password: newEncryptedPassword,
            mode: 'EMAIL',
            adminType: 'ADMIN'
          }
        }
        let encryptedText = this.CryptoJS.AES.encrypt(JSON.stringify(reqBody), process.env.VUE_APP_ENCRYPTION_KEY).toString()
        let res = await base_axios_instance.post('/updatePassword', {key: encryptedText});
        alert('Password changed successfully');
        this.$router.go();
      } catch(error) {
        console.log(error);
        alert('error');
        this.$router.go();
      } finally {
        this.submitLogin = false;
      }
    },
    renderSignInButton() {
        window.google.accounts.id.initialize({
        client_id: process.env.VUE_APP_CLIENT_ID,
        callback: this.handleCredentialResponse,
        prompt: 'select_by',
        scope: "email"
      })
      window.google.accounts.id.renderButton(
        this.$refs.googleLoginBtn, {
          text: 'signin',
          // shape: 'circle',
          size: 'large',
          width: '300px',
          theme: 'filled_blue',
        }
      )
      // window.google.accounts.id.prompt();
    },
    async signInViaEmailPassword() {
      if (this.isSubmitBtnDisableForEmailPassword) return;
      this.submitLogin = true;
      
      try {
       let encryptedText = this.CryptoJS.AES.encrypt(this.password, process.env.VUE_APP_ENCRYPTION_KEY).toString();
       let response = await base_axios_instance.post('/passwordLogin', { adminType: 'ADMIN', email: this.email, password: encryptedText });
       if (response.data.firstLogin) {
            this.submitLogin = false;
            this.showLoginComponent = false;
            this.mode = 'EMAIL';
            return;
       }
       let token = response.data.token;
       response = await base_axios_instance.post('/getAdmin', { token : response.data.token, adminType : "ADMIN", typeOfUser : "ADMIN" });
       if(response.data.message == "SUCCESS"){
          this.submitLogin = false;
          this.$cookies.set("token",token, "1w");
          this.$router.push({
            name: "AdminHome",
          });
        } else {
            this.submitLogin = false;
            this.isLoading = false;
            window.alert("Sorry !! You are unauthorized !!");
            this.$router.go();
        } 
      } catch (error) {
          this.submitLogin = false;
          if(error.response.status == 401){
              this.isLoading = false;
              window.alert("Sorry !! You are unauthorized !!");
              this.$router.go();
          }
      }
    },
    async handleCredentialResponse(signInSuccessResponse) { 
      try {
        this.submitLogin = true;
        this.isLoading = true;
        const accessToken = signInSuccessResponse.credential;
        const signedInuserDetails = await jwt_decode(accessToken);
        var email = signedInuserDetails.email;
        if (["kbzbank.com", "kbxdigital.com", "kbxholdings.com","doodleblue.com","mymedicine.com.mm", "codestax.ai"].indexOf(email.split("@")[1]) >= 0) {
                const encryptedText = this.CryptoJS.AES.encrypt(accessToken + " " + email + " " + "ADMIN", process.env.VUE_APP_ENCRYPTION_KEY).toString();
                var createAdminTokenData = {
                  key : encryptedText
                }
                axios.post(process.env.VUE_APP_BACKEND_URL + "/createAdminToken", createAdminTokenData)
                .then((CreateAdminTokenResponse) => {
                  if (CreateAdminTokenResponse.data.firstLogin) {
                    this.submitLogin = false;
                    this.showLoginComponent = false;
                    this.email = email;
                    this.gauthToken = encryptedText;
                    this.mode = 'GAUTH';
                    return ;
                  }
                  var getUserAccessData = {
                    token : CreateAdminTokenResponse.data.data,
                    adminType : "ADMIN",
                    typeOfUser : "ADMIN"
                  }
                  axios
                  .post(process.env.VUE_APP_BACKEND_URL + "/getAdmin", getUserAccessData)
                  .then((response) => {
                      this.submitLogin = false;
                      if(response.data.message == "SUCCESS"){
                        this.$cookies.set("token",CreateAdminTokenResponse.data.data, "1w");
                        this.$router.push({
                          name: "AdminHome",
                        });
                      }
                      else {
                        this.submitLogin = false;
                        this.isLoading = false;
                        window.alert("Sorry !! You are unauthorized !!");
                        this.$router.go();
                      }
                  })
                  .catch((error) => {
                      this.submitLogin = false;
                    if(error.response.status == 401){
                      this.isLoading = false;
                      window.alert("Sorry !! You are unauthorized !!");
                      this.$router.go();
                    }
                  });
                })
                .catch((CreateAdminTokenError) => {
                  this.submitLogin = false;
                  if(CreateAdminTokenError.response.status == 401){
                    this.isLoading = false;
                    window.alert("Sorry !! You are unauthorized !!");
                    this.$router.go();
                  }
                  else{
                    this.isLoading = false;
                    window.alert("Something went wrong...");
                    this.$router.go();
                  }
                });
          }
              else{
                this.submitLogin = false;
                this.isLoading = false;
                window.alert("Sorry !! You are unauthorized !!");
                this.$router.go();
              }
      } catch (err) {
          console.log(err);
          this.submitLogin = false;
          this.isLoading = false;
          window.alert("Something went wrong");
          this.$router.go();
      }
    },
    closeDialogErrorModal(){
         this.errorModal = false;
         this.$router.go();
    },
  },
};
</script>

<style scoped>

label {
  padding-bottom: 4px;
}
.rememberMeDiv {
  height: 10px;
  padding: 15px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.rememberMeDiv span {
  color: rgba(130, 130, 130, 1);
}

.adminContainer {
  box-sizing: border-box;
  height: 100vh;
  width: 100%;
  display: flex;
  background: rgba(244, 246, 250, 1);
  border: none;
}
.divImage {
  height: 100%;
  width: 100%;
}
.leftDiv {
  width: 40%;
}
.loginContainer {
  box-sizing: border-box;
  width: 60%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.formDiv {
  width: 65%;
}
.spc {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-size: cover;
  width:100%;
}
.loginBox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: url("https://d3vsnl8idgwrlw.cloudfront.net/adminBackgroundImage.svg");
  background-size: cover;
  width:85%;
  min-height: 80%;
  padding-top: 90px;
  padding-bottom: 40px;
}
.content {
  width: 100%;
}
 .setNewPswdh1 {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media only screen and (max-width: 900px) {
  .adminContainer {
    display: flex;
    flex-direction: column;
    /* background:white; */
  }
  .leftDiv {
    width: 100%;
}
  .adminContainer img {
    width: 100%;
    height: 80%;
    object-fit:cover;
  }
  .loginContainer {
    width: 100%;
    position: relative;
    /* bottom: 15%; */
  }
  h1{
    display: block;
    text-align: left;
  }
  .formDiv {
    width: 100%;
  }  
  .loginBox {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-image: none;
    background: white;
    background-size: cover;
    width:100%;
    padding-top: 30px;
    padding-bottom: 20px;
    padding-left: 30px;
    padding-right: 30px;
    border-radius: 40px 40px 0px 0px;
}
 .setNewPswdh1 {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.8rem;
}
}
@media only screen and (max-width: 700px) and (max-height: 700px) {
  .adminContainer img {
    width: 100%;
    height: 70%;
    object-fit:cover;
  }
  .loginContainer {
    width: 100%;
    position: relative;
    bottom: 40%;
  }
    .loginBox {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-image: none;
    background: white;
    background-size: cover;
    width:100%;
    padding-top: 40px;
    padding-bottom: 40px;
    padding-left: 30px;
    padding-right: 30px;
    border-radius: 40px 40px 0px 0px;
}
}
</style>